import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonProps = {
  variant?: 'black' | 'white' | 'pink' | 'blue' | 'lightblue';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variants = {
  black: 'bg-black text-white',
  white: 'bg-white text-black',
  pink: 'bg-sb-pink text-white',
  blue: 'bg-sb-blue text-white',
  lightblue: 'bg-sb-l-blue text-white',
};

export default function Button({
  className,
  variant = 'black',
  ...props
}: ButtonProps): JSX.Element {
  return (
    <button
      type="button"
      {...props}
      className={twMerge(
        'disabled:opacity-30 transition-all flex whitespace-nowrap flex-row hover:bg-opacity-80 disabled:hover:bg-opacity-100 items-center justify-center font-ginto-bold gap-2.5 bg-black text-white text-[17px] leading-[17px] rounded-sb-lg p-5 h-[60px]',
        variants[variant],
        className,
      )}
    />
  );
}
